form.published input.ng-invalid {
  border: 2px solid #ff9494 !important;
  background-color: #ffd2d2 !important;
}
form.published altai-text-editor.ng-invalid {
  outline: 2px solid red !important;
}
form.published altai-tag-selector.ng-invalid {
  display: block;
  border: 2px solid #ff9494 !important;
  background-color: #ffd2d2;
}
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}
altai-text-editor {
  display: block;
  flex: 1;
}
